import React, { ReactElement, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import DeleteIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  textFieldRoot: {
    position: 'relative',
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
      backgroundColor: 'white !important',
    },
    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button,':
      {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
  },
  inputProps: {
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
      backgroundColor: 'white !important',
    },
  },
  input: {
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
      backgroundColor: 'white !important',
    },
    '&.withBackground .MuiInputBase-input': {
      // background: 'rgba(244, 246, 252)',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 0,
      },
      '&.Mui-focused': {
        '& .MuiInputBase-input': {
          borderColor: theme.palette.text.input,
        },
      },
      '&.Mui-error': {
        '& .MuiInputBase-input': {
          color: theme.palette.error.main,
        },
      },
      '&.Mui-disabled': {
        '& .MuiInputBase-input': {
          backgroundColor: theme.palette.background.light,
          borderColor: theme.palette.text.input,
        },
      },
    },
    '& .MuiInputBase-input': {
      padding: theme.spacing(3, 0),
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(2, 0),
      },
      height: '48px',
      boxSizing: 'border-box',
      borderRadius: 0,
      borderBottom: `2px solid #737882`,
      appearance: 'none',
      ...theme.typography.input,
    },
    '&.outlined .MuiInputBase-input': {
      border: `1px solid #737882`,
      padding: theme.spacing(3.25),
    },
    '&.outlined:hover .Mui-focused .MuiInputBase-input': {
      border: `2px solid ${theme.palette.text.input}`,
      padding: theme.spacing(3),
    },
    '&.outlined:focused .Mui-focused .MuiInputBase-input': {
      border: `1px solid ${theme.palette.text.input}`,
      padding: theme.spacing(3.25),
    },
    '& .Mui-focused .MuiInputBase-input': {
      borderBottom: `2px solid ${theme.palette.text.input}`,
    },
    '&.withBackground .Mui-focused .MuiInputBase-input': {
      borderBottom: `1px solid ${theme.palette.text.input}`,
    },
    '& .MuiInput-underline:after, & .MuiInput-underline:before': {
      display: 'none',
    },
  },
  endIcon: {
    position: 'absolute',
    top: '10px',
    right: '5px',
  },
  clearIcon: {},
}))

export type TextInputProps = React.HTMLProps<HTMLInputElement> &
  TextFieldProps & {
    className?: string | ''
    id: string
    error?: string | boolean | false
    type?: string | 'text'
    initialValue?: string | 'text'
    defaultValue?: string | 'text'
    multiline?: boolean | false
    rows?: string | number | undefined
    rowsMax?: string | number | undefined
    outlined: boolean | false
    clearable?: boolean | false
  }

export default function TextInput({
  className,
  id,
  type,
  multiline,
  rows,
  initialValue = '',
  defaultValue = '',
  rowsMax,
  outlined,
  clearable,
  error,
  ...props
}: TextInputProps): ReactElement {
  const classes = useStyles()
  const [value, setValue] = useState(initialValue || defaultValue || '')

  const handleChange = (e: KeyboardEvent) => {
    setValue(e.target.value)
  }

  const handleDelete = (e: MouseEvent) => {
    setValue('')
    if (props.onKeyUp) {
      props.onKeyUp(e)
    }
    if (props.onChange) {
      props.onChange(e)
    }
  }

  return (
    <div className={classes.textFieldRoot}>
      <TextField
        className={clsx(
          className,
          classes.input,
          {
            withBackground: multiline,
            outlined: outlined,
          },
          `input-type-${type}`
        )}
        value={value}
        onChange={handleChange}
        data-testid="input"
        id={id}
        type={type}
        multiline={multiline}
        inputProps={{
          className: classes.inputProps,
          style: type === 'date' ? { textTransform: 'uppercase' } : {},
        }}
        error={error}
        rows={rows}
        rowsMax={rowsMax}
        {...props}
      />
      <div className={classes.endIcon}>
        {props.endicon &&
          (!clearable || !value || value.length === 0) &&
          props.endicon}

        {clearable && value && value.length > 0 && (
          <DeleteIcon className={classes.clearIcon} onClick={handleDelete} />
        )}
      </div>
    </div>
  )
}
