import React, { ReactElement, useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Fade } from '@material-ui/core'
import { TypeButton } from '@material-ui/core/styles/createTheme'
import Icon from '@components/core/icon'
import FontSize from '@config/theme/definitions/fontSize'
import useCustomCursor from '@system/hooks/useCustomCursor'
import { Link as GatsbyLink } from 'gatsby'

export const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(12),
    padding: theme.spacing(3.5, 7),
    width: '100%',
    borderRadius: 0,
    overflow: 'hidden',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    outline: 'none',
    background: 'none',
    fontFamily: 'inherit',
    cursor: 'none',
    position: 'relative',
    '&.disabled': {
      backgroundColor: theme.palette.button.disabled,
      color: theme.palette.button.disabledText,
      pointerEvents: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  fullwidth: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  main: {
    ...theme.typography.buttonPrimary,
    backgroundColor: theme.palette.button.main,
    color: theme.palette.button.mainText,
    transition: 'color 0.6s',
    position: 'relative',
    '& > span': {
      position: 'relative',
    },
    '@media (hover: hover)': {
      '&::before': {
        content: "''",
        position: 'absolute',
        top: '59%',
        left: '50%',
        width: '100%',
        height: '0',
        padding: '0 0 100% 0',
        margin: '-50%',
        backgroundColor: theme.palette.button.mainHover,
        borderRadius: '50%',
        opacity: 0,
        transform: 'scale(0.01)',
        transition: 'transform 0.6s, opacity 0.2s 0.4s',
      },
      '&:hover': {
        color: theme.palette.button.mainTextHover,
        textDecoration: 'none',
        '&::before': {
          opacity: 1,
          transform: 'scale(2)',
          transition: 'transform 0.6s, opacity 0.2s',
        },
      },
    },
  },
  secondary: {
    ...theme.typography.buttonPrimary,
    backgroundColor: theme.palette.button.secondary,
    color: theme.palette.button.secondaryText,
  },
  tertiary: {
    ...theme.typography.buttonPrimary,
    backgroundColor: theme.palette.button.tertiary,
    color: theme.palette.button.tertiaryText,
  },
  text: {
    ...theme.typography.buttonText,
    ...theme.typography.link,
    padding: 0,
    display: 'inline',
    color: theme.palette.text.default,
  },
  icon: {
    height: '54px',
    width: '54px',
    padding: 0,
    borderRadius: 0,
    backgroundColor: ({ invert }: StyleProps): string =>
      invert
        ? theme.palette.button.tertiaryText
        : theme.palette.button.tertiary,
    color: ({ invert }: StyleProps): string =>
      invert ? theme.palette.button.mainText : theme.palette.text.default,
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      height: FontSize['4xl'],
      width: 'auto',
    },
    '& span:before': {
      content: 'none',
      position: 'absolute',
      width: 'calc(100% - 4px)',
      height: 'calc(100% - 4px)',
      top: '2px',
      left: '2px',
      border: `1px solid ${theme.palette.button.tertiaryTextHover}`,
      borderRadius: '4px',
      display: 'block',
    },
    '&:focus span:before': {
      content: `''`,
    },
    '&:not(.disabled):hover': {
      backgroundColor: ({ invert }: StyleProps): string =>
        invert
          ? theme.palette.button.tertiary
          : theme.palette.button.tertiaryText,
      color: ({ invert }: StyleProps): string =>
        invert
          ? theme.palette.button.tertiaryText
          : theme.palette.button.mainText,
    },
  },
  play: {},
  underlined: {},
  backtop: {},
  linkClasses: {},
  iconLeft: {
    marginRight: theme.spacing(2),
    verticalAlign: '-2px',
  },
  iconRight: {
    marginLeft: theme.spacing(2),
    verticalAlign: '-2px',
  },
  disabled: {},
  disabledText: {},
  mainHover: {},
  mainText: {},
  mainTextHover: {},
  secondaryHover: {},
  secondaryText: {},
  secondaryTextHover: {},
  tertiaryHover: {},
  tertiaryText: {},
  tertiaryTextHover: {},
}))

interface IWrapper {
  children?: React.ReactChild | React.ReactNode | string
  className?: string
  hidden?: boolean | false
  to?: string
  backtop?: boolean | false
  isExternal?: boolean | false
  disabled?: boolean | false
  icon?: string
  iconPosition?: string
  type: keyof TypeButton | 'icon' | 'play' | 'underlined'
  formSubmit?: boolean | false
  fullwidth?: boolean | false
  isVisible?: boolean | true
  invert?: boolean | false
}

type StyleProps = {
  invert?: boolean
}

function Wrapper({
  children,
  className,
  type,
  fullwidth,
  icon,
  iconPosition,
  hidden,
  isExternal,
  to,
  formSubmit,
  backtop,
  isVisible,
  invert,
  ...props
}: IWrapper): ReactElement {
  const classes = useStyles({ invert })
  const { setCursorType } = useCustomCursor()

  const btnClasses = clsx(className, classes.root, classes[type], {
    [classes.play]: type === 'icon' && icon === 'Play',
    [classes.fullwidth]: fullwidth,
    disabled: props.disabled,
  })

  function scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  function scrollTo(to: string) {
    const el = document.querySelector(to)
    if (el) {
      window.scrollTo({
        top: el.getBoundingClientRect().y + window.scrollY - 100,
        behavior: 'smooth',
      })
    }
  }

  function renderIcon() {
    if (type === 'icon' && !!icon) {
      return <Icon name={icon} size="large" color="inherit" />
    } else {
      const name = icon ? icon : 'ChevronDown'

      const buttonClass =
        iconPosition !== 'right' ? classes.iconLeft : classes.iconRight

      if (name) {
        return (
          <Icon
            name={name}
            className={buttonClass}
            size="inherit"
            color="inherit"
          />
        )
      }
    }

    return null
  }

  if (hidden) {
    return <></>
  } else if (to && type !== 'icon') {
    if (to.startsWith('#')) {
      return (
        <button
          type="button"
          className={btnClasses}
          onClick={() => scrollTo(to)}
          onMouseEnter={() => setCursorType('hide')}
          onMouseLeave={() => setCursorType('')}
          {...props}
        >
          {children}
        </button>
      )
    }
    return isExternal ? (
      <a
        href={to}
        target="_blank"
        rel="noreferrer"
        className={clsx(btnClasses, classes.linkClasses)}
        onMouseEnter={() => setCursorType('hide')}
        onMouseLeave={() => setCursorType('')}
        onClick={() => setCursorType('')}
        {...props}
      >
        <span>{children}</span>
      </a>
    ) : (
      <GatsbyLink
        to={to}
        className={clsx(btnClasses, classes.linkClasses)}
        onMouseEnter={() => setCursorType('hide')}
        onMouseLeave={() => setCursorType('')}
        onClick={() => setCursorType('')}
        {...props}
      >
        <span>{children}</span>
      </GatsbyLink>
    )
  } else if (backtop) {
    return (
      <Fade in={isVisible} timeout={1000}>
        <button
          type="button"
          className={clsx(btnClasses, classes.backtop)}
          {...props}
          onClick={scrollTop}
          onMouseEnter={() => setCursorType('hide')}
          onMouseLeave={() => setCursorType('')}
        >
          <span>
            <Icon name="ChevronUp" size="default" />
          </span>
        </button>
      </Fade>
    )
  } else if (type === 'icon') {
    if (to) {
      return (
        <GatsbyLink
          to={to}
          className={clsx(btnClasses, classes.icon)}
          onMouseEnter={() => setCursorType('hide')}
          onMouseLeave={() => setCursorType('')}
          onClick={() => setCursorType('')}
          {...props}
        >
          <span>{renderIcon()}</span>
        </GatsbyLink>
      )
    }
    return (
      <button
        type="button"
        className={clsx(btnClasses, classes.icon)}
        onMouseEnter={() => setCursorType('hide')}
        onMouseLeave={() => setCursorType('')}
        onClick={() => setCursorType('')}
        {...props}
      >
        <span>{renderIcon()}</span>
      </button>
    )
  } else {
    return (
      <button
        type={formSubmit ? 'submit' : 'button'}
        className={btnClasses}
        onMouseEnter={() => setCursorType('hide')}
        onMouseLeave={() => setCursorType('')}
        onClick={() => setCursorType('')}
        {...props}
      >
        {icon && iconPosition !== 'left' && renderIcon()}
        <span>{children}</span>
        {icon && iconPosition === 'right' && renderIcon()}
      </button>
    )
  }
}

/**
 *
 * @description You can set "to" for internal links, "href" for external links or "link",
 * when object should determine by the given string, if it is internal or external.
 * If link is set and link includes "www." it is considerd as external.
 * Otherwise if link is set on link does NOT include "www." it is considered as internal.
 */
export type ButtonProps = React.HTMLProps<HTMLButtonElement> & {
  children?: React.ReactChild | React.ReactNode | string
  className?: string
  hidden?: boolean | false
  to?: string
  backtop?: boolean | false
  isExternal?: boolean | false
  icon?: string
  iconPosition?: string | undefined
  type?: keyof TypeButton | 'icon' | 'play' | 'underlined'
  formSubmit?: boolean | false
  fullwidth?: boolean | false
  disabled?: boolean | false
  invert?: boolean | false
}

export default function Button({
  className,
  children,
  hidden,
  to,
  backtop,
  isExternal,
  icon,
  iconPosition,
  type,
  formSubmit,
  fullwidth,
  ...props
}: ButtonProps): ReactElement {
  const [isVisible, setVisibility] = useState(false)

  useEffect(() => {
    if (!backtop) return
    setVisibility(false)
    document.addEventListener('scroll', toggleVisibility)
    return () => {
      document.removeEventListener('scroll', toggleVisibility)
    }
  }, [])

  function toggleVisibility() {
    if (window.pageYOffset > 50) {
      setVisibility(true)
    } else {
      setVisibility(false)
    }
  }

  return (
    <Wrapper
      className={className}
      hidden={hidden}
      to={to}
      backtop={backtop}
      icon={icon}
      iconPosition={iconPosition}
      type={type || 'main'}
      formSubmit={formSubmit}
      fullwidth={fullwidth}
      isVisible={isVisible}
      isExternal={isExternal}
      {...props}
    >
      {children}
    </Wrapper>
  )
}
