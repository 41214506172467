import React, { ReactElement } from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from './helperText'

import clsx from 'clsx'
import closedIcon from '@material-ui/icons/Add'
import openedIcon from '@material-ui/icons/Remove'
import DeleteIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  selectRoot: {
    cursor: 'pointer',
    marginBottom: theme.spacing(8),
    '& .MuiSelect-select': {
      paddingRight: theme.spacing(12),
    },
    '& svg': {
      color: theme.palette.text.input,
      right: theme.spacing(2),
    },
    '& .MuiSelect-select:focus': {
      background: 'none',
    },
    '& .MuiInput-underline:after': {
      transform: 'scaleX(1)',
    },
  },
  inputlabel: {
    color: '#acb3c0',
    top: theme.spacing(2.5),
    left: theme.spacing(0),
    '&.Mui-focused,&.MuiFormLabel-filled': {
      top: theme.spacing(0),
      left: theme.spacing(0),
      color: theme.palette.text.input + '!important',
    },
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 220,
    width: '100%',
    '& .MuiInput-underline:after': {
      borderColor: '#737882',
    },
    '& .Mui-focused.MuiInput-underline:after': {
      borderColor: theme.palette.text.input,
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    minWidth: '120px',
    width: '100%',
    maxWidth: '520px',
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(2, 0),
    },
    height: '48px',
    boxSizing: 'border-box',
    borderRadius: 0,
    appearance: 'none',
    ...theme.typography.input,
  },
  item: {
    backgroundColor: '#05050a',
    color: '#ffffff',
    '&.Mui-selected, &.Mui-selected:hover, &.MuiListItem-button:hover': {
      background: '#41444b',
    },
    '&:first-child': {
      display: 'none',
    },
  },
  menuRoot: {
    marginLeft: theme.spacing(0),
    minWidth: 'auto',
    '& input': {
      display: 'none',
    },
  },
  menuPaper: {
    maxWidth: '100%',
  },
  deleteButton: {
    position: 'absolute',
    right: '8px',
    top: '23px',
    width: '24px',
    height: '24px',
    zIndex: 0,
  },
  errorField: {
    marginLeft: theme.spacing(1),
  },
}))

export type ISelectBoxProps = {
  id?: string
  className?: string
  label?: string
  infoText?: string
  placeholder?: string
  onChange?: null
  items?: Array<ISelectItem>
  initialValue?: string
  defaultValue?: string
}

export type ISelectItem = {
  text?: string
  value?: string | number
}
export default function SelectBox(props: ISelectBoxProps): ReactElement {
  const {
    id,
    className,
    label,
    placeholder,
    onChange,
    items,
    initialValue = '',
    defaultValue = '',
  } = props

  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [val, setValue] = React.useState(initialValue || defaultValue)

  const meta = null

  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value as string)
    onChange(event.target.value as string)
  }

  const getIcon = () => {
    if (open === true) {
      return openedIcon
    } else {
      if (val) {
        return DeleteIcon
      } else {
        return closedIcon
      }
    }
  }

  return (
    <div className={clsx(classes.selectRoot, className)}>
      <FormControl className={clsx(classes.formControl)}>
        {label && (
          <InputLabel
            className={classes.inputlabel}
            id={`${id}-label`}
            htmlFor={id}
          >
            {label}
          </InputLabel>
        )}
        <Select
          id={id}
          labelId={`${id}-label`}
          className={classes.select}
          value={val}
          onChange={handleChange}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          MenuProps={menuProps}
          IconComponent={getIcon()}
          classes={{
            root: classes.menuRoot,
          }}
        >
          <MenuItem value="" className={classes.item}>
            <em>{placeholder}</em>
          </MenuItem>

          {items?.map((member: ISelectItem, idx: number) => (
            <MenuItem
              className={classes.item}
              value={member.value}
              key={`${id}-select-item${idx}`}
            >
              {member.text}
            </MenuItem>
          ))}
        </Select>
        {val && val.length > 0 && (
          <a
            className={classes.deleteButton}
            onClick={(e) => {
              setValue(defaultValue)
              onChange(defaultValue)
            }}
          />
        )}
      </FormControl>
      {meta && meta.touched && meta.error ? (
        <div className={classes.errorField}>
          <FormHelperText spaced type="error">
            {meta.error}
          </FormHelperText>
        </div>
      ) : null}
    </div>
  )
}
